import React, { useMemo } from "react";
import TopControl from "../../common/TopControl";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const SpecialtyCards = ({ data }) => {
  const image = getImage(data.image);

  // Function to format the category slug
  const formatCategorySlug = (category) => {
    return category
      .replace(/\s+/g, '-')  // Replace spaces with dashes
      .replace(/&/g, 'and')   // Replace ampersands with 'and'
      .replace(/_/g, '-and-')  // Replace underscores with '-and-'
      .replace(/[^a-zA-Z0-9\-]/g, '') // Remove non-alphanumeric characters except dashes
      .toLowerCase();        // Convert to lowercase
  };

  return (
    <div className="w-full md:w-1/2 px-8 py-12">
      <div
        className="flex flex-col w-full bg-white rounded-xl overflow-hidden h-450 relative"
        style={{ boxShadow: "2px 2px 20px 2px #AAAAAA" }}
      >
        <div className="w-full h-350">
          <GatsbyImage
            image={image}
            objectFit="cover"
            objectPosition="center"
            className="h-full"
            alt={data.title}
          />
        </div>
        <div className="flex-grow flex items-center justify-center text-2xl font-medium">
          {data.title}
        </div>
        <Link
          to={`/portfolios/${formatCategorySlug(data.location)}`} // Use the formatted slug here
          className="absolute w-full h-full top-0 left-0 z-20"
        >
          &nbsp;
        </Link>
      </div>
    </div>
  );
};

const HomeSpecialtySection = () => {
  const prismicHighlight = useStaticQuery(graphql`
    query MyHighlightQuery {
      prismicHome {
        data {
          projects {
            project_title
            project_location
            project_link {
              uid
            }
            project_image {
              alt
              gatsbyImageData(width: 650, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  const highlightData = useMemo(() => {
    if (prismicHighlight) {
      const data = prismicHighlight.prismicHome.data.projects.map(project => {
        const {
          project_title,
          project_location,
          project_image,
          project_link,
        } = project;
        return {
          title: project_title,
          location: project_location,
          image: project_image,
          uid: project_link.uid,
        };
      });
      return data;
    }

    return [];
  }, [prismicHighlight]);

  return (
    <div className="w-full flex flex-col items-center justify-center py-12">
      <div className="w-full max-w-7xl">
        <TopControl sectionTitle="Our style" title="We specialize in" />
        <div className="flex flex-row flex-wrap justify-center items-start">
          {highlightData.map(data => {
            return <SpecialtyCards key={data.uid} data={data} />;
          })}
        </div>
      </div>
      <Link
        to="/portfolios"
        className="px-12 py-4 w-full max-w-sm uppercase bg-site-yellow text-white text-center rounded-md"
      >
        See Our Portfolio
      </Link>
    </div>
  );
};

export default HomeSpecialtySection;